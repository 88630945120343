// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"f40e786d814f44c3fae8015f220ccbeb84f569ad"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import { SupabaseClient } from '@supabase/supabase-js';
import { supabaseIntegration } from '@supabase/sentry-js-integration';

Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.01,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,
    maxBreadcrumbs: 500,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    integrations: [
        supabaseIntegration(SupabaseClient, Sentry, {
            tracing: true,
            breadcrumbs: true,
            errors: true,
        }),

        Sentry.browserTracingIntegration({
            shouldCreateSpanForRequest: (url) => {
                return !url.startsWith(`${process.env.NEXT_PUBLIC_SUPABASE_URL}/rest`);
            },
        }),

        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
        }),

        Sentry.feedbackIntegration({
            colorScheme: 'system',
            showBranding: true,
            autoInject: false,
            showName: false,
            formTitle: '버그 리포팅',
            messageLabel: '증상',
            messagePlaceholder: '버그나 개선사항을 발견했다면 이곳에 입력해주세요.',
            submitButtonLabel: '전송',
            cancelButtonLabel: '취소',
            themeDark: {
                background: '#1f1f1f',
            },
            successMessageText: '의견 주셔서 감사합니다. 빠른 시일 내에 확인하겠습니다.',
            enableScreenshot: false,
        }),
    ],

    // Optional: 오류 발생 시 보고 대화 상자 표시 설정
    // beforeSend(event, hint) {
    //   if ((process.env.NODE_ENV === 'development') && event.exception && event.event_id) {
    //     Sentry.showReportDialog({
    //       eventId: event.event_id,
    //       lang: "ko",
    //       title: "죄송합니다. 오류가 발생했습니다",
    //       subtitle: "개발팀에 문제가 전달 되었습니다. 좀 더 자세한 내용을 입력해주시면 더 빠른 해결이 가능합니다.",
    //       subtitle2: "",
    //       labelComments: "문제에 대한 자세한 설명",
    //       labelSubmit: "전송",
    //       showBranding: false,
    //       successMessage: "의견 주셔서 감사합니다. 빠른 시일 내에 확인하겠습니다.",
    //     });
    //   }
    //   return event;
    // },
});
